// src/components/InterviewDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './Interview.css'

const InterviewDetail = () => {
    const { interviewId } = useParams();
    const [interview, setInterview] = useState(null);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'; // Fallback to localhost

        fetch(`${baseUrl}/api/interviews/${interviewId}`)
            .then(response => response.json())
            .then(data => {
                setInterview(data);
                // Set page title after fetching the interview details
                document.title = `"${data.PageTitle}" - Verser`;
            })
            .catch(error => console.error('Error fetching interview details:', error));
    }, [interviewId]);

    if (!interview) {
        return <div>Loading...</div>;
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'Unknown Date';
    
        // Parse the ISO date string
        const parts = dateString.split('-');
        if (parts.length < 3) {
            return 'Invalid Date'; // or return the original dateString to handle unexpected formats gracefully
        }
    
        const year = parts[0];
        const monthIndex = parseInt(parts[1], 10) - 1; // Convert "01" to 0 (January), "02" to 1 (February), etc.
        const day = parseInt(parts[2], 10); // Remove leading zeros
    
        // Array of month names
        const monthNames = ["January", "February", "March", "April", "May", "June", 
                            "July", "August", "September", "October", "November", "December"];
    
        // Check if monthIndex is valid
        if (monthIndex < 0 || monthIndex >= monthNames.length) {
            return 'Invalid Date'; // Month index out of range
        }
    
        const monthName = monthNames[monthIndex];
    
        // Construct the formatted date string
        return `${monthName} ${day}, ${year}`;
    };
    

    // Function to render comma-separated names and IDs as links
    const renderNamesWithLinks = (details, basePath) => {
        return details ? details.split('|').map((detail, index) => {
            const [name, id] = detail.split(':');
            return (
                <div key={index}>
                    <Link to={`${basePath}${id.trim()}`}>
                        {name.trim()}
                    </Link>
                </div>
            );
        }) : <p>None found. <Link to={`/edit/${interviewId}`}>Add this info</Link></p>;
    };

    const processPeopleMentioned = (peopleMentionedDetails) => {
        return peopleMentionedDetails
        ? peopleMentionedDetails.split('|').map(detail => {
            const [name] = detail.split(':'); // Extract the name part
            return name.trim();
          }).join('|') // Rejoin the names with pipe delimiter for renderSearchLinks
        : '';
    };
    
    // Function to render comma-separated topics as search query links
    const renderSearchLinks = (values, basePath) => {
        return values ? values.split('|').map((value, index) => (
            <div key={index}>
                <Link to={`${basePath}${encodeURIComponent(value.trim())}`}>
                    {value.trim()}
                </Link>
            </div>
        )) : <p>None found. <Link to={`/edit/${interviewId}`}>Add this info</Link></p>;
    };

    const urls = interview.URLs ? interview.URLs.split('|').map(url => url.trim()) : [];
    const firstURL = urls.length > 0 ? urls[0] : '#';
    const additionalURLs = urls.slice(1);
    // Format the date
    const formattedDate = interview.Date ? formatDate(interview.Date) : 'Date not found';

    return (
        <div className='interview-details'>
            <h1>Interview Details</h1>
                <h3>Title & Link</h3>
                <a href={firstURL} target="_blank" rel="noopener noreferrer">
                    {interview.PageTitle}
                </a>

            <h3>Publish Date</h3><p>{formattedDate}</p>
            {additionalURLs.length > 0 && (
                <div>
                    <h3>Additional URLs</h3>
                    {additionalURLs.map((url, index) => (
                        <div key={index}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                {url}
                            </a>
                        </div>
                    ))}
                </div>
            )}
            <div>
                <h3>Interviewees</h3>
                {renderNamesWithLinks(interview.IntervieweeDetails, "/person/")}
            </div>
            <div>
                <h3>Interviewers</h3>
                {renderNamesWithLinks(interview.InterviewerDetails, "/person/")}
            </div>
            <div>
                <h3>Publisher</h3>
                {interview.PublisherID ? (
                    <Link to={`/publisher/${interview.PublisherID}`}>
                        {interview.PublisherName}
                    </Link>
                ) : (
                    <p>None found. <Link to={`/edit/${interviewId}`}>Add this info</Link></p>
                )}
            </div>
            <div>
                <h3>Topics</h3>
                {renderSearchLinks(interview.TopicNames, "/search?q=")}
            </div>
            <div>
                <h3>People Mentioned</h3>
                {renderSearchLinks(processPeopleMentioned(interview.PeopleMentionedDetails), "/search?q=")}
            </div>
            <div>
                <h3>Works Mentioned</h3>
                {renderSearchLinks(interview.WorkTitles, "/search?q=")}
            </div>
            <div>
                <h3>Places Mentioned</h3>
                {renderSearchLinks(interview.PlaceNames, "/search?q=")}
            </div><br></br>
            <div className="edit-interview-message">
                Let us know if there's something wrong or missing. <Link to={`/edit/${interviewId}`}>Edit this interview</Link>
            </div>
        </div>
    );
};

export default InterviewDetail;
